class Header {
    constructor(selector) {
        this.selector = selector;
        this.header_height = this.selector.offsetHeight;

        this._windowScroll = this._windowScroll.bind(this);
        window.addEventListener('scroll', this._windowScroll);
    }
    _windowScroll() {
        let from_top = this.selector.offsetTop;
        if (window.pageYOffset > from_top) {
            this.selector.classList.add('header--scrolled');
        } else {
            this.selector.classList.remove('header--scrolled');
        }
    }
}
export default Header;