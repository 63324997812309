class Ebook {
    constructor(selector) {
        this.button = selector;

        this.modal = document.querySelector('[data-element="modal"]');
        this.modalMask = document.querySelector('.modal-mask');
        this.contactCloseButton = document.querySelector('#contactCloseButton');

        this.inwestorBtn = this.modal.querySelector('#inwestorBtn');
        this.przedsiebiorcaBtn = this.modal.querySelector('#przedsiebiorcaBtn');
        this.inwestorContent = this.modal.querySelector('[data-target="inwestor"]');
        this.przedsiebiorcaContent = this.modal.querySelector('[data-target="przedsiebiorca"]');
        this.modalButton = this.modal.querySelector('#modalButton');

        this.custom_miejsce_inwestycji = document.getElementsByName('custom_miejsce_inwestycji')[0];
        this.custom_przeprowadzka = document.getElementsByName('custom_przeprowadzka')[0];
        this.custom_reorganizacja = document.getElementsByName('custom_reorganizacja')[0];
        this.custom_wehikul = document.getElementsByName('custom_wehikul')[0];

        this.custom_miejsce_dzialalnosci = document.getElementsByName('custom_miejsce_dzialalnosci')[0];
        this.custom_company = document.getElementsByName('custom_company')[0];
        this.custom_branza = document.getElementsByName('custom_branza')[0];

        this.agreeMoreBtn = document.getElementById('agreeMoreBtn');

        this._agreeMoreBtn = this._agreeMoreBtn.bind(this);
        if(this.agreeMoreBtn !== null) {
            this.agreeMoreBtn.addEventListener('click', this._agreeMoreBtn);
        }

        this._buttonClick = this._buttonClick.bind(this);
        this.button.addEventListener('click', this._buttonClick);

        this._contactCloseButton = this._contactCloseButton.bind(this);
        this.contactCloseButton.addEventListener('click', this._contactCloseButton);

        this._modalMask = this._modalMask.bind(this);
        this.modalMask.addEventListener('click', this._modalMask);

        this._inwestorBtn = this._inwestorBtn.bind(this);
        this.inwestorBtn.addEventListener('click', this._inwestorBtn);

        this._przedsiebiorcaBtn = this._przedsiebiorcaBtn.bind(this);
        this.przedsiebiorcaBtn.addEventListener('click', this._przedsiebiorcaBtn);
    }
    _buttonClick(e) {
        e.preventDefault();

        this.campaign_code = this.button.getAttribute('data-id');
        this.ebook_title = this.button.getAttribute('data-title');

        this.modal.querySelector('[data-title]').innerHTML = this.ebook_title;
        this.modal.querySelector('[name="campaign_token"]').value = this.campaign_code;

        this.modal.style.display = 'flex';
        this.modalMask.style.display = 'block';
    }
    _contactCloseButton(event) {
        this.modal.style.display = 'none';
        this.modalMask.style.display = 'none';
    }
    _modalMask() {
        this.modal.style.display = 'none';
        this.modalMask.style.display = 'none';
    }
    _agreeMoreBtn() {
        let agreeMore = document.getElementById('agreeMore');
        if(agreeMore !== null){
            agreeMore.style.display = 'block';
            this.agreeMoreBtn.style.display = 'none';
        }
    }
    _inwestorBtn() {
        this.inwestorContent.style.display = "block";
        this.przedsiebiorcaContent.style.display = "none";
        this.modalButton.style.display = "block";
        this.custom_miejsce_inwestycji.setAttribute("required", "");
        this.custom_przeprowadzka.setAttribute("required", "");
        this.custom_reorganizacja.setAttribute("required", "");
        this.custom_wehikul.setAttribute("required", "");
        this.custom_miejsce_dzialalnosci.removeAttribute("required");
        this.custom_company.removeAttribute("required");
        this.custom_branza.removeAttribute("required");
    }
    _przedsiebiorcaBtn() {
        this.przedsiebiorcaContent.style.display = "block";
        this.inwestorContent.style.display = "none";
        this.modalButton.style.display = "block";
        this.custom_miejsce_inwestycji.removeAttribute("required");
        this.custom_przeprowadzka.removeAttribute("required");
        this.custom_reorganizacja.removeAttribute("required");
        this.custom_wehikul.removeAttribute("required");
        this.custom_miejsce_dzialalnosci.setAttribute("required", "");
        this.custom_company.setAttribute("required", "");
        this.custom_branza.setAttribute("required", "");
    }
}
export default Ebook;