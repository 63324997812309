import 'core-js/stable/array/for-each';

const setCookie = (cname, cvalue, expire, path = '/') => {
    let d = new Date();
    d.setTime(d.getTime() + expire);
    let expires = 'expires=' + d.toUTCString();
    document.cookie = cname + '=' + cvalue + '; ' + expires + ';path=' + path;
}

/** cname - nazwa szukanego ciasteczka */
/** getCookie - pobieramy ciasteczko o określonej nazwie */
const getCookie = cname => {
    let name = cname + '=';
    /** decodedUri - wszystkie ciasteczka przechowywane na stronie przedstawione w czytelnej formie */
    let decodedUri = decodeURIComponent(document.cookie);
    /** tablica przechowująca wszystkie ciasteczka */
    let ca = decodedUri.split(';');
    let val = false;
    ca.forEach(el => {
        while (el.charAt(0) == ' ') el = el.substring(1);
        if (el.indexOf(name) == 0) {
            val = el.substring(name.length, el.length);
            return false;
        };
        /** czy aktualne ciasteczko jest naszym ciasteczkiem */
    });
    return val;
}

export const get = getCookie;
export const set = setCookie;

