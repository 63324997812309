class CountriesTabs {
    constructor(selector) {
        this.selector = selector;
        this.tabs = this.selector.querySelector('[data-element="tabs"]');
        this.content = this.selector.querySelector('[data-element="content"]');

        if (this.tabs !== null) {
            let tabs = this.tabs.querySelectorAll('[data-id]');
            if (tabs !== null) {
                this._clickTab = this._clickTab.bind(this);

                tabs.forEach(tab => {
                    tab.addEventListener('click', this._clickTab);
                });
            }
        }
    }
    _clickTab(e) {
        e.preventDefault();

        let target = e.target.getAttribute('data-id');
        console.log(target);
        if (target !== null) {
            let tabs = this.tabs.querySelectorAll('[data-id]');
            if (tabs !== null) {
                tabs.forEach(tab => {
                    tab.classList.remove('active');
                });
            }
            this.tabs.querySelector('[data-id="' + target + '"]').classList.add('active');

            let element = this.content.querySelector('[data-target="' + target + '"]');
            if (element !== null) {
                let contbs = this.content.querySelectorAll('[data-target]');
                if (contbs !== null) {
                    contbs.forEach(cnt => {
                        cnt.classList.remove('active');
                    });
                    element.classList.add('active');
                }
            }
        }
    }
}
export default CountriesTabs;