import Ebook from "./ebook";

if (window.Element && !Element.prototype.closest) {
    Element.prototype.closest =
    function(s) {
        var matches = (this.document || this.ownerDocument).querySelectorAll(s),
            i,
            el = this;
        do {
            i = matches.length;
            while (--i >= 0 && matches.item(i) !== el) {};
        } while ((i < 0) && (el = el.parentElement));
        return el;
    };
}

import 'core-js/stable/array/for-each';
import Swiper from 'swiper';
import map from './map';
import scrollIt from './scrollIt';
import Scrollbar from 'smooth-scrollbar';
import form from './form';
import * as cookie from './cookie';
import Glide from '@glidejs/glide';
import Header from "./header";
import CountriesTabs from "./countriesTabs";

if (document.getElementsByClassName('js-swiper-container').length > 0) {
    let mySwiper = new Swiper ('.js-swiper-container', {
        autoplay: {
            delay: 5000
        },
        // grabCursor: true,
        loop: true,
        speed: 700,
        pagination: {
            el: '.swiper-pagination',
            type: 'bullets',
            clickable: 'true'
        }
    });
}

if (document.getElementsByClassName('js-swiper-container-second').length > 0) {
    let mySwiper = new Swiper ('.js-swiper-container-second', {
        autoplay: {
            delay: 8000
        },
        loop: true,
        speed: 1000,
        containerModifierClass: 'swiper-container-second',
        pagination: {
            el: '.swiper-pagination-second',
            type: 'bullets',
            clickable: 'true'
        }
    });
}

if (document.getElementsByClassName('js-swiper-customer-recommendations').length > 0) {
    let mySwiper = new Swiper ('.js-swiper-customer-recommendations', {
        autoplay: {
            delay: 8000
        },
        loop: true,
        speed: 1000,
        containerModifierClass: '',
        pagination: {
            el: '.swiper-pagination-second',
            type: 'bullets',
            clickable: 'true'
        }
    });
}

let scroller = document.querySelector('.js-scroll-to');
if (scroller !== null) {
    scroller.addEventListener('click', function(event) {
        event.preventDefault();
        let target = this.dataset.target;
        let targetObject = document.querySelector(`.${target}`);
        if (targetObject !== null) {
            scrollIt(
                targetObject,
                500,
                'linear'
            );
        }
    });
}

let hamburger = document.querySelector('.hamburger');
let menu = document.querySelector('#mobile-menu');
let mask = document.querySelector('#mask');
let mobileMenu = document.querySelector('#mobile-menu');

const toggleMenu = (e) => {
    e.preventDefault();
    hamburger.classList.toggle('active');
    menu.classList.toggle('toggled');
    mask.classList.toggle('toggled');
    if (menu.classList.contains('toggled')) {
        document.body.style.overflow = 'hidden';
        document.querySelector('html').style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'auto';
        document.querySelector('html').style.overflow = 'auto';
    }
}

if (mobileMenu !== null) {
    Scrollbar.init(mobileMenu);
}

hamburger.addEventListener('click', toggleMenu);
mask.addEventListener('click', toggleMenu);

let contentButtons = document.querySelectorAll('.js-button-more');
if(contentButtons !== null) {
    const showContent = function() {
        let currentTeam = this.closest('.js-team');
        let currentButtonBg = this.closest('.person__button-background');
    
        let contentContainer = currentTeam.querySelectorAll('.js-content');
        let button = currentTeam.querySelectorAll('.js-button-more');
        let buttonBg = currentTeam.querySelectorAll('.person__button-background');
        let activeContent = currentTeam.querySelector(`[data-target-id="${this.dataset.id}"]`);
    
        if (this.classList.contains('active')) {
            this.classList.remove('active');
            activeContent.classList.remove('active');
            currentButtonBg.classList.remove('active');
        } else {
            button.forEach(function(el) {
                el.classList.remove('active');
            });
            contentContainer.forEach(function(el) {
                el.classList.remove('active');
            });
            buttonBg.forEach(function(el) {
                el.classList.remove('active');
            });
    
            this.classList.toggle('active');
            activeContent.classList.toggle('active');
            currentButtonBg.classList.toggle('active');
        }
    }

    contentButtons.forEach(el => el.addEventListener('click', showContent));
}

let authorButton = document.querySelector('.js-authors-more');
if(authorButton !== null) {
    const showAuthors = function(e) {
        e.preventDefault();
        let authorContainer = this.closest('.authors');
        let authorsList = authorContainer.querySelector('.authors__content');
    
        if (this.classList.contains('active')) {
            this.classList.remove('active');
            this.querySelector('.authors__icon').src = "/wp-content/themes/panasiuk/dist/images/more-icon--25.png"
            authorsList.classList.remove('active');
        } else {
            this.classList.toggle('active');
            this.querySelector('.authors__icon').src = "/wp-content/themes/panasiuk/dist/images/less-icon--25.png"
            authorsList.classList.toggle('active');
        }
    }

    authorButton.addEventListener('click', showAuthors);
}

if(window.innerWidth <= 700) {
    let teamMembers = document.querySelectorAll('[data-id]');
    if (teamMembers !== null) {
        teamMembers.forEach(function(el) {
            let teamMember = el.closest('.js-person');
            if (teamMember !== null) {
                let contentContainer = document.querySelector(`[data-target-id="${el.dataset.id}"]`);
                if (contentContainer !== null) {
                    teamMember.appendChild(contentContainer);
                }
            }
        });
    }
}

let formElement = document.querySelector('.js-form');
if (formElement !== null) {
    form.init({
        action: '/wp-admin/admin-ajax.php', 
        handler: formElement,
        data: element => {
            let data = {
                email: '',
                phone: '',
                name: '',
                topic: '',
                message: '',
                action: 'nt_send_form'
            };

            let email = element.querySelector('#email').value;
            let phone = element.querySelector('#phone').value;
            let name = element.querySelector('#name').value;
            let topic = element.querySelector('#topic').value;
            let message = element.querySelector('#message').value;

            if (email.length > 0) data.email = email;
            if (phone.length > 0) data.phone = phone;
            if (name.length > 0) data.name = name;
            if (topic.length > 0) data.topic = topic;
            if (message.length > 0) data.message = message;

            return data;
        },
        beforeSubmit: element => {
            let loader = element.querySelector('.loader__background');
            loader.style.display = 'flex';
            loader.querySelector('.loader').style.display = 'block';
        },
        success: (element, response) => {
            let loader = element.querySelector('.loader__background');
            let successMessage = loader.querySelector('.loader__success');
            successMessage.innerHTML = response.message;
            loader.querySelector('.loader').style.display = 'none';
            element.querySelector('#email').value = '';
            element.querySelector('#name').value = '';
            element.querySelector('#topic').value = '';
            element.querySelector('#message').value = '';
            successMessage.style.display = 'block';
            setTimeout(() => loader.style.display = 'none', 5000);
            // console.log(response);
        },
        error: (element, error) => {
            let loader = element.querySelector('.loader__background');
            let errorMessage = loader.querySelector('.loader__error');
            // console.log(errorMessage);
            errorMessage.innerHTML = error;
            loader.querySelector('.loader').style.display = 'none';
            errorMessage.style.display = 'block';
            setTimeout(() => loader.style.display = 'none', 2000);
            // console.log(error);
        }
    })
}

let newsletterElement = document.querySelectorAll('.js-newsletter');
if (newsletterElement !== null) {
    newsletterElement.forEach(function(el, key) {
        form.init({
            action: '/wp-admin/admin-ajax.php', 
            handler: el,
            data: element => {
                let data = {
                    email: '',
                    action: 'nt_add_subscriber'
                };
    
                let email = element.querySelector('#email').value;
                let type = element.querySelector('#publication');
                if (email.length > 0) data.email = email;
                if (type !== null) data.type = 'publication';
                else data.type = 'normal';
    
                return data;
            },
            beforeSubmit: element => {
                let loader = element.querySelector('.loader__background');
                loader.style.display = 'flex';
                loader.querySelector('.loader').style.display = 'block';
            },
            success: (element, response) => {
                let loader = element.querySelector('.loader__background');
                let successMessage = loader.querySelector('.loader__success');
                successMessage.innerHTML = response.message;
                loader.querySelector('.loader').style.display = 'none';
                element.querySelector('#email').value = '';
                successMessage.style.display = 'block';
                setTimeout(() => loader.style.display = 'none', 5000);
                // console.log(response);
            },
            error: (element, error) => {
                let loader = element.querySelector('.loader__background');
                let errorMessage = loader.querySelector('.loader__error');
                // console.log(errorMessage);
                errorMessage.innerHTML = error;
                loader.querySelector('.loader').style.display = 'none';
                errorMessage.style.display = 'block';
                setTimeout(() => loader.style.display = 'none', 2000);
                // console.log(error);
            }
        })
    })
}

let expandButtons = document.querySelectorAll('.js-expand-button');
if (expandButtons !== null) {
    const expandContent = function(e) {
        e.preventDefault();
        let expandContainer = this.closest('.js-expand');
        let target = expandContainer.querySelector('.js-expand-target');
        let foldButton = expandContainer.querySelector('.js-fold-button');
        // if (this.classList.contains('active')) {
        //     target.style.display = 'none';
        //     this.classList.toggle('active');
        //     this.innerHTML = 'Rozwiń';
        // } else {
            target.style.display = 'block';
            this.style.display = 'none';
            foldButton.style.display = 'block';
            // this.innerHTML = 'Zwiń';
        // }
    }

    expandButtons.forEach(el => el.addEventListener('click', expandContent));
}

let foldButtons = document.querySelectorAll('.js-fold-button');
if (foldButtons !== null) {
    const foldContent = function(e) {
        e.preventDefault();
        let foldContainer = this.closest('.js-expand');
        let target = foldContainer.querySelector('.js-expand-target');
        let expandButton = foldContainer.querySelector('.js-expand-button');

        target.style.display = 'none';
        this.style.display = 'none';
        expandButton.style.display = 'inline';
    }

    foldButtons.forEach(el => el.addEventListener('click', foldContent));
}

let yearButtons = document.querySelectorAll('.archives__year');
if(yearButtons !== null) {
    const showYear = function(e) {
        e.preventDefault();
        let item = this.closest('.archives__item');
        let submenu = item.querySelector('.archives__submenu');
    
        if (this.classList.contains('active')) {
            this.querySelector('.archives__icon').src = "/wp-content/themes/panasiuk/dist/images/icon-down.png"
            this.classList.remove('active');
            submenu.classList.remove('active');
        } else {
            this.querySelector('.archives__icon').src = "/wp-content/themes/panasiuk/dist/images/icon-up.png"
            this.classList.toggle('active');
            submenu.classList.toggle('active');
        }
    }

    yearButtons.forEach(el => el.addEventListener('click', showYear));
}

let monthButtons = document.querySelectorAll('.archives__month');
if(monthButtons !== null) {
    const showMonth = function(e) {
        e.preventDefault();
        let item = this.closest('.archives__submenu-item');
        let submenu = item.querySelector('.archives__sub-submenu');
    
        if (this.classList.contains('active')) {
            this.querySelector('.archives__icon').src = "/wp-content/themes/panasiuk/dist/images/icon-down.png"
            this.classList.remove('active');
            submenu.classList.remove('active');
        } else {
            this.querySelector('.archives__icon').src = "/wp-content/themes/panasiuk/dist/images/icon-up.png"
            this.classList.toggle('active');
            submenu.classList.toggle('active');
        }
    }

    monthButtons.forEach(el => el.addEventListener('click', showMonth));
}

let cookieAcceptance = document.querySelector('.js-cookie');
// console.log(cookieAcceptance);
if (cookieAcceptance !== null) {
    // console.log(cookie.get('nt_cookie_policy_accepted'));
    if (cookieAcceptance.classList.contains('nv') && cookie.get('nt_cookie_policy_accepted') === false) {
        cookieAcceptance.classList.remove('nv');
        // console.log('1');
    } else if (!cookieAcceptance.classList.contains('nv') && cookie.get('nt_cookie_policy_accepted') !== false) {
        cookieAcceptance.classList.toggle('nv');
        // console.log('2');
    }
    let cookieButton = cookieAcceptance.querySelector('.js-cookie-button');
    if (cookieButton !== null) {
        cookieButton.addEventListener('click', function(e) {
            e.preventDefault();
            if (!cookieAcceptance.classList.contains('nv')) cookieAcceptance.classList.toggle('nv');
            if (cookie.get('nt_cookie_policy_accepted') === false) cookie.set('nt_cookie_policy_accepted', 1, (365 * 24 * 60 * 60 * 1000)); 
        });
    }
}
window.addEventListener('DOMContentLoaded', () => {
    let posts_crls = null;
    let posts_carousel = document.querySelector('.js-prepare-posts-carousel');
    if (posts_carousel !== null) {
        let post_count = posts_carousel.querySelectorAll('[data-element="item"]');
        if (post_count.length) {
            let width = window.innerWidth;
            let perView = 3;
            if (width > 767) {
                perView = 3;
            } else if (width <= 767 && width >= 451) {
                perView = 2;
            } else if (width <= 450) {
                perView = 1;
            }

            if (post_count.length <= perView) {
                posts_carousel.classList.add('static');
            } else {
                posts_crls = new Glide(posts_carousel, {
                    type: 'slider',
                    perView: perView,
                    autoplay: 3000,
                    bound: true,
                    startAt: 0
                });
                posts_crls.mount();
                posts_crls.update();
            }
        }
    }

    let download_buttons = document.querySelectorAll('.js-download-ebook');
    if (download_buttons !== null) {
        download_buttons.forEach(btn => {
            new Ebook(btn);
        });
    }

    let question_widget = document.querySelector('.js-question-widget');
    if (question_widget !== null) {
        let container = question_widget.querySelector('[data-container]');
        if (container !== null) {
            let images = container.querySelectorAll('img');
            if (images.length > 0) {
                let current_index = 0;

                setInterval(() => {
                    container.querySelector('[data-index="' + current_index + '"]').style.opacity = '0';

                    current_index++;
                    if (current_index > 1) current_index = 0;

                    container.querySelector('[data-index="' + current_index + '"]').style.opacity = '1';
                }, 3000);
            }
        }
    }

    let header = document.querySelector('.js-header');
    if (header !== null) {
        new Header(header);
    }

    let countries_tabs = document.querySelector('.js-countries-tabs');
    if (countries_tabs !== null) {
        new CountriesTabs(countries_tabs);
    }

    let links = document.querySelectorAll('a');
    if (links !== null) {
        links.forEach(link => {
            let href_string = link.getAttribute('href');
            if (href_string.includes('#')) {
                let element_id = href_string.substring(href_string.indexOf('#') + 1);
                if (element_id !== '') {
                    let section = document.getElementById(element_id);
                    if (section !== null) {
                        link.addEventListener('click', e => {
                            e.preventDefault();

                            let headerOffset = document.querySelector('.js-header').offsetHeight + 40;
                            let section_position = window.pageYOffset + section.getBoundingClientRect().top;
                            let offsetPosition = section_position - headerOffset - 20;

                            window.scrollTo({
                                top: offsetPosition,
                                behavior: 'smooth'
                            });

                            setTimeout(() => {
                                let interactive_elements = document.querySelectorAll('.interactive-element');

                                let window_width = window.innerWidth;
                                let window_height = window.innerHeight;
                                let window_top = document.body.scrollTop;
                                let window_bottom = (window_top + window_height);

                                for (const inter_element of interactive_elements) {
                                    let element_height = inter_element.clientHeight;
                                    let element_top = document.body.scrollTop + inter_element.getBoundingClientRect().top;
                                    let element_bottom = (element_top + element_height) + 100;

                                    if ((element_bottom >= window_top) && element_top <= window_bottom) {
                                        inter_element.classList.add('visible');
                                    }
                                }
                            }, 100);
                        });
                    }
                }
            }
        });
    }
});