import extend from './extend';

let defaultOptions = {
    handler: null,
    action: '',
    data: form => {
        return {};
    },
    beforeSubmit: form => {},
    success: (form, response) => {},
    error: (form, error) => {}
}

const form = {
    options: {},
    init: options => {
        if (options.handler !== null && options.action.length > 0) {
            let hashed = options.handler.id;
            form.options[hashed] = extend(defaultOptions, options);
            form.handler(options.handler);
        }
    },
    sendForm: (url, data) => {
        let formBody = Object.keys(data).map(key => {return key + '=' + data[key]}).join('&');
        return fetch(url, {
            method: 'POST',
            cache: 'no-cache',
            credentials: 'same-origin',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            redirect: 'follow',
            body: formBody
        }).then(response => { 
            if (!response.ok) {
                return response.json().then(function(response) {
                    throw Error(response.message);
                })
            } else {
                return response.json();
            }
        });
    },
    handler: (element) => {
        // let submitForm = document.querySelector(form.options.handler);
        element.addEventListener('submit', function(e) {
            e.preventDefault();
            let hashed = element.id;
            form.options[hashed].beforeSubmit(this);
            let data = form.options[hashed].data(this);
            form.sendForm(form.options[hashed].action, data)
                .then(response => {
                    form.options[hashed].success(this, response);
                })
                .catch(error => {
                    form.options[hashed].error(this, error);
                });
        });
    }
}

export default form;